<template>
  <div>
    <div class="canvasBox">
      <div class="vertical" :class="{ vertical1: customer === 'qingtian' }">
        <iframe
          src="./2d/obliqueRoof_VerticalView.html"
          ref="iframe"
          width="100%"
          height="100%"
          scrolling="no"
          frameborder="0"
          id="iframe"
        ></iframe>
        <!-- 晴天tab -->
        <div class="tabs" v-if="customer === 'qingtian'">
          <span
            :class="{ spanact: activeName == 'a' }"
            @click="
              activeName = 'a';
              SideViewNum = '侧视图';
              SideViewShow = false;
            "
            class="span1"
          >俯视图</span
          >
          <!-- <span
            :class="{ spanact: activeName == 'b' }"
            @click="
              activeName = 'b';
              SideViewShow = true;
            "
          >{{ SideViewNum }}</span
          > -->
          <!-- <span
            :class="{ spanact: activeName == 'c' }"
            @click="
              activeName = 'c';
              SideViewNum = '侧视图';
              SideViewShow = false;
            "
          >前视图</span
          >
          <span
            :class="{ spanact: activeName == 'd' }"
            @click="
              activeName = 'd';
              SideViewNum = '侧视图';
              SideViewShow = false;
            "
            class="span2"
          >后视图</span
          > -->
        </div>
        <div class="btnbox">
          <div class="info" @click="infoEvent">基本信息</div>
          <div
            class="shelter"
            v-if="activeName == 'a'"
            @click.stop="shelterTypeShowEvent"
          >
            遮挡信息
          </div>
          <div
            class="door"
            v-if="activeName == 'c' || activeName == 'd'"
            @click.stop="shelterTypeShowEvent"
          >
            门窗信息
          </div>
          <ul class="options" v-show="shelterTypeShow && activeName == 'a'">
            <li
              v-for="(item, index) in shelterInfoType"
              :key="index"
              @click="chooseShelterType(item)"
            >
              {{ item }}
            </li>
          </ul>
          <ul
            class="options options1"
            v-show="shelterTypeShow && (activeName == 'c' || activeName == 'd')"
          >
            <li
              v-for="(item, index) in doorWindowType"
              :key="index"
              @click="chooseShelterType(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <ul
          class="options options2"
          :class="{ options3: customer === 'qingtian' }"
          v-if="SideViewShow"
        >
          <li @click="chooseSideView('侧视图1')">侧视图1</li>
          <li @click="chooseSideView('侧视图2')">侧视图2</li>
        </ul>
      </div>
      <!-- 锦浪tab -->
      <!-- eslint-disable vue/valid-v-model -->
      <van-tabs
        v-if="customer === 'jinlang'"
        v-model:active="activeName"
        background="rgba(0,0,0,0)"
        title-inactive-color="#696C74"
        line-height="4px"
        line-width="25%"
        color="#04CC66"
        title-active-color="#04CC66"
        @click-tab="clickTab"
      >
        <van-tab title="俯视图" name="a"> </van-tab>
        <!-- <van-tab :title="SideViewNum" name="b"> </van-tab> -->
        <!-- <van-tab title="前视图" name="c"> </van-tab>
        <van-tab title="后视图" name="d"> </van-tab> -->
      </van-tabs>
    </div>

    <button class="previewBtn" @click="previewEvent">预览</button>
    <van-popup v-model:show="popShow">
      <BaseInfo @popupClose="getPopupClose" />
    </van-popup>
    <van-popup v-model:show="shelterShow">
      <Parapet
        @popupClose="getPopupClose"
        v-show="shelterComShow == '女儿墙'"
      />
      <Chimney @popupClose="getPopupClose" v-show="shelterComShow == '烟囱'" />
      <Heater @popupClose="getPopupClose" v-show="shelterComShow == '热水器'" />
      <RoofObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋面障碍物'"
      />
      <OutdoorObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋外障碍物'"
      />
      <NeighborHouse
        @popupClose="getPopupClose"
        v-show="shelterComShow == '邻居房屋'"
      />
      <TelegraphPole
        @popupClose="getPopupClose"
        v-show="shelterComShow == '电线杆'"
      />
      <Wire @popupClose="getPopupClose" v-show="shelterComShow == '电线'" />
      <Tree @popupClose="getPopupClose" v-show="shelterComShow == '树'" />
      <SunRoom
        @popupClose="getPopupClose"
        v-show="shelterComShow == '阳光房'"
      />
      <!-- <Billboard
        @popupClose="getPopupClose"
        v-show="shelterComShow == '广告牌'"
      /> -->
      <RidgeDecoration
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋脊装饰物'"
      />
      <Door @popupClose="getPopupClose" v-show="shelterComShow == '门'" />
      <Window @popupClose="getPopupClose" v-show="shelterComShow == '窗'" />
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  toRaw,
  getCurrentInstance,
  toRefs,
} from "vue";
import FarmerInfo from "./../../components/FarmerInfo";
import BaseInfo from "./BaseInfo";
import Parapet from "@/components/obstacles/Parapet";
import Chimney from "@/components/obstacles/Chimney";
import Heater from '@/components/obstacles/Heater'
import { guid } from '@/plugins/guid';
// import Heater from "@/components/shopingBarrier/Heater.vue";
import RoofObstacles from "@/components/obstacles/RoofObstacles";
import OutdoorObstacles from "@/components/obstacles/OutdoorObstacles";
import NeighborHouse from "@/components/obstacles/NeighborHouse";
import TelegraphPole from "@/components/obstacles/TelegraphPole";
import Wire from "@/components/obstacles/Wire";
import Tree from "@/components/obstacles/Tree";
// import Billboard from "@/components/obstacles/Billboard";
import RidgeDecoration from "@/components/obstacles/RidgeDecoration";
import SunRoom from "@/components/obstacles/SunRoom.vue";
import Door from "@/components/obstacles/Door";
import Window from "@/components/obstacles/Window";
import emitter from '../../plugins/eventBus'
import { useRouter } from "vue-router";
import { UploadFile } from '@/api/api'
import { Dialog } from 'vant';
// import { ObliqueRoofInfo } from "@/api/api";

export default {
  name: "SlopingRoof",
  components: {
    FarmerInfo,
    BaseInfo,
    Parapet,
    Chimney,
    Heater,
    RoofObstacles,
    OutdoorObstacles,
    NeighborHouse,
    TelegraphPole,
    Wire,
    Tree,
    SunRoom,
    // Billboard,
    RidgeDecoration,
    Door,
    Window,
  },
  setup() {
    const iframe = ref(null);
    onMounted(() => {
      nextTick(() => {
        data.iframeWin = iframe.value.contentWindow;
      });
    });
    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData;
    const SideViewShow = ref(false);
    const router = useRouter();
    const data = reactive({
      customer: window.customer,
      activeName: "a",
      SideViewNum: "侧视图",
      shelterTypeShow: false,
      shelterInfoType: [
        "女儿墙",
        "烟囱",
        "热水器",
        "屋面障碍物",
        "邻居房屋",
        "阳光房",
        "电线杆",
        "电线",
        "树",
        // "广告牌",
        "屋外障碍物",
        "屋脊装饰物",
      ],
      doorWindowType: ["门", "窗"],
      popShow: false,
      shelterShow: false,
      shelterComShow: false,
      iframeWin: null,
      spanClick: 'handleObstacleClick', // html中需要响应的事件
    });
    window[data.spanClick] = (params) => {
      methodsMap.editObstacle(params)
    }
    onMounted(() => {
      nextTick(() => {
        data.iframeWin = iframe.value.contentWindow
      })
      // 获取斜屋顶的基本数据
      var dataInfo = JSON.parse(sessionStorage.getItem('slopingAgain'))
      if (JSON.parse(sessionStorage.getItem('barrier')) !== null) {
        var barr = JSON.parse(sessionStorage.getItem('barrier'))
        if (barr !== null) {
          // if (barr.billboardObstacleModels.length>0) {
          //   barr.billboardObstacleModels.forEach(val => {
          //     var data02 = {}
          //     val.name = '斜屋顶广告牌'
          //     if (val.trueOrFalseRemove === true) {
          //       val.trueOrFalseRemove = 1
          //     } else {
          //       val.trueOrFalseRemove = 0
          //     }
          //     for (const i in val) {
          //       if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove' && i !== 'name') {
          //         val[i] = val[i] / 10
          //       }
          //     }
          //     data02.XCoordinates = val.xCoordinates
          //     data02.YCoordinates = val.yCoordinates
          //     data02.EastWestDistance = val.eastwestDistance
          //     data02.id = val.id
          //     data02.name = val.name
          //     data02.Height = val.height
          //     data02.SourthNorthDistance = val.sourthnorthDistance
          //     data02.TrueOrFalseRemove = val.TrueOrFalseRemove
          //     methodsMap.sendMsgTo2D(data02)
          //   })

          // // methodsMap.sendMsgTo2D(dataInfo.billboardObstacleModels)
          // }
          if (barr.chimneyObstacleModels.length>0) {
            barr.chimneyObstacleModels.forEach(val => {
              var data03 = {}
              // val.name = '烟囱'
              if (val.chimneyShapeType === 2) {
                val.name = '斜屋顶圆形烟囱'
              } else {
                val.name = '斜屋顶矩形烟囱'
              }
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.TrueOrFalseAdjustable === true) {
                val.TrueOrFalseAdjustable = 1
              } else {
                val.TrueOrFalseAdjustable = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'TrueOrFalseAdjustable' && i !== 'name' && i !== 'chimneyType' && i !== 'chimneyShapeType') {
                  val[i] = val[i] / 10
                }
              }
              data03.XCoordinates = val.XCoordinates
              data03.YCoordinates = val.YCoordinates
              data03.ChimneyType = val.chimneyType
              data03.EastWestLength = val.eastWestLength
              data03.id = val.id
              data03.name = val.name
              data03.Height = val.height
              data03.SourthNorthLength = val.sourthNorthLength
              data03.TrueOrFalseRemove = val.trueOrFalseRemove
              data03.CurrentHeight = val.currentHeight
              data03.TrueOrFalseAdjustable = val.TrueOrFalseAdjustable
              data03.RearHeight = val.rearHeight
              data03.ChimneyDiam = val.chimneyDiam
              methodsMap.sendMsgTo2D(data03)
            })
            // methodsMap.sendMsgTo2D(dataInfo.chimneyObstacleModels)
          }
          if (barr.heaterObstacleModels.length>0) {
            barr.heaterObstacleModels.forEach(val => {
              console.log(val, '999999999999')
              var data0 = {}
              val.name = '斜屋顶热水器'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.trueOrFalseMove === true) {
                val.trueOrFalseMove = 1
                data0.RearTrueOrFalseRemove = val.rearTrueOrFalseRemove / 10
                data0.RearTrueOrFalseMove = val.rearTrueOrFalseMove / 10
              } else {
                val.trueOrFalseMove = 0
                data0.RearTrueOrFalseRemove = val.xCoordinates / 10
                data0.RearTrueOrFalseMove = val.yCoordinates / 10
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'name' && i !== 'trueOrFalseMove') {
                  val[i] = val[i] / 10
                }
              }
              data0.XCoordinates = val.xCoordinates
              data0.YCoordinates = val.yCoordinates
              data0.SourthHeight = val.sourthHeight
              data0.NorthHeight = val.northHeight
              data0.EastWestDistance = val.eastwestDistance
              data0.id = val.id
              data0.name = val.name
              data0.SourthNorthDistance = val.sourthnorthDistance
              data0.TrueOrFalseRemove = val.trueOrFalseRemove
              data0.TrueOrFalseMove = val.trueOrFalseMove
              // data0.RearTrueOrFalseRemove = val.rearTrueOrFalseRemove
              // data0.RearTrueOrFalseMove = val.rearTrueOrFalseMove
              methodsMap.sendMsgTo2D(data0)
            })
            // methodsMap.sendMsgTo2D(data)
            // methodsMap.sendMsgTo2D(barr.heaterObstacleModels, { name: '热水器' })
          }
          if (barr.neighborHouseObstacleModels.length>0) {
            barr.neighborHouseObstacleModels.forEach(val => {
              var data04 = {}
              val.name = '斜屋顶邻居房屋'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data04.XCoordinates = val.xCoordinates
              data04.YCoordinates = val.yCoordinates
              data04.EastWestDistance = val.eastwestDistance
              data04.id = val.id
              data04.name = val.name
              data04.Height = val.height
              data04.SourthNorthDistance = val.sourthnorthDistance
              methodsMap.sendMsgTo2D(data04)
            })

            // methodsMap.sendMsgTo2D(barr.neighborHouseObstacleModels)
          }
          if (barr.outSideObstacleModels.length>0) {
            barr.outSideObstacleModels.forEach(val => {
              var data05 = {}
              val.name00 = '斜屋顶屋外障碍物'

              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'Name' && i !== 'name00' && i !== 'trueOrFalseRemove') {
                  val[i] = val[i] / 10
                }
              }
              data05.XCoordinates = val.xCoordinates
              data05.YCoordinates = val.yCoordinates
              data05.EastWestDistance = val.eastwestDistance
              data05.id = val.id
              data05.name = val.name00
              data05.Name = val.Name
              data05.Height = val.height
              data05.SourthNorthDistance = val.sourthnorthDistance
              data05.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data05)
            })
          }
          if (barr.parapetWallsObstacleModels.length>0) {
            barr.parapetWallsObstacleModels.forEach(val => {
              var data06 = {}
              val.name = '斜屋顶女儿墙'
              if (val.trueOrFalseRemove === 1) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }

              if (val.TrueOrFalseDecorations === true) {
                val.TrueOrFalseDecorations = 1
              } else {
                val.TrueOrFalseDecorations = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove' && i !== 'TrueOrFalseDecorations') {
                  val[i] = val[i] / 10
                }
              }
              data06.id = val.id
              data06.name = val.name
              data06.Thickness = val.thickness
              data06.EastHeight = val.eastHeight
              data06.WestHeight = val.westHeight
              data06.SourthHeight = val.sourthHeight
              data06.NorthSideHigh = val.northHeight
              data06.TrueOrFalseDecorations = val.TrueOrFalseDecorations
              data06.DecorationsHeight = val.decorationHeight
              data06.TrueOrFalseRemove = val.trueOrFalseRemove
              console.log(data06, ' -+++++++++++++++++++')
              methodsMap.sendMsgTo2D(data06)
            })

            // methodsMap.sendMsgTo2D(barr.parapetWallsObstacleModels)
          }
          if (barr.roofingObstacleModels.length>0) {
            barr.roofingObstacleModels.forEach(val => {
              var data07 = {}
              val.name1 = '斜屋顶屋面障碍物'
              if (val.trueOrFalseMove === true) {
                data07.RearTrueOrFalseRemove = val.rearTrueOrFalseRemove / 10
                data07.RearTrueOrFalseMove = val.rearTrueOrFalseMove / 10
              } else {
                data07.RearTrueOrFalseRemove = val.xCoordinates / 10
                data07.RearTrueOrFalseMove = val.yCoordinates / 10
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'name1' && i !== 'trueOrFalseRemove' && i !== 'trueOrFalseMove') {
                  val[i] = val[i] / 10
                }
              }
              data07.id = val.id
              data07.name = val.name1
              data07.Name = val.name
              data07.XCoordinates = val.xCoordinates
              data07.YCoordinates = val.yCoordinates
              data07.EastWestDistance = val.eastwestDistance
              data07.SourthNorthDistance = val.sourthnorthDistance
              data07.Height = val.height
              data07.TrueOrFalseMove = val.trueOrFalseMove
              data07.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data07)
            })

            // methodsMap.sendMsgTo2D(barr.roofingObstacleModels)
          }
          if (barr.treeObstacleModels.length>0) {
            barr.treeObstacleModels.forEach(val => {
              var data08 = {}
              if (val.trueOrFalseRemove === 1) {
                data08.TrueOrFalseRemove = val.trueOrFalseRemove
              } else {
                data08.TrueOrFalseRemove = val.canopyDiameter
              }
              val.name = '斜屋顶树'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove') {
                  val[i] = val[i] / 10
                }
              }
              data08.id = val.id
              data08.name = val.name
              data08.XCoordinates = val.xCoordinates
              data08.YCoordinates = val.yCoordinates
              data08.TrunkHeight = val.TrunkHeight
              data08.TrunkDiameter = val.TrunkDiameter
              data08.CanopyHeight = val.CanopyHeight
              data08.CanopyDiameter = val.CanopyDiameter
              // data8.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data08)
            })

            // methodsMap.sendMsgTo2D(barr.treeObstacleModels)
          }
          if (barr.wireObstacleModels.length>0) {
            barr.wireObstacleModels.forEach(val => {
              var data09 = {}
              val.name = '斜屋顶电线'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.wireType === 2) {
                if (val.wireLocation === 4) {
                  val.wireLocation = 1
                } else if (val.wireLocation === 5) {
                  val.wireLocation = 2
                } else if (val.wireLocation === 6) {
                  val.wireLocation = 3
                } else if (val.wireLocation === 7) {
                  val.wireLocation = 4
                }
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove' && i !== 'wireType' && i !== 'WireLocation' && i !== 'Voltage') {
                  val[i] = val[i] / 10
                }
              }
              data09.id = val.id
              data09.name = val.name
              data09.WireHeight = val.WireHeight
              data09.WireDiameter = val.WireDiameter
              data09.Voltage = val.Voltage
              data09.TrueOrFalseRemove = val.trueOrFalseRemove
              data09.WireType = val.wireType
              data09.WireLocation = val.WireLocation
              data09.distance1 = val.southeastCorner
              data09.distance2 = val.northeastCorner
              methodsMap.sendMsgTo2D(data09)
            })

            // methodsMap.sendMsgTo2D(barr.wireObstacleModels)
          }
          if (barr.wirePoleObstacleModels.length>0) {
            barr.wirePoleObstacleModels.forEach(val => {
              var data01 = {}
              val.name = '斜屋顶电线杆'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data01.XCoordinates = val.xCoordinates
              data01.YCoordinates = val.yCoordinates
              data01.Diameter = val.diameter
              data01.Height = val.height
              data01.TrueOrFalseRemove = val.TrueOrFalseRemove
              data01.id = val.id
              data01.name = val.name
              methodsMap.sendMsgTo2D(data01)
            })
          }
          if (barr.roadObstacleModels.length>0) {
            barr.roadObstacleModels.forEach(val => {
              if (val.AisleType === 2) {
                val.AisleType = 0
              }
              var data010 = {}
              val.name = '斜屋顶上人通道'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'AisleType') {
                  val[i] = val[i] / 10
                }
              }
              data010.XCoordinates = val.xCoordinates
              data010.YCoordinates = val.yCoordinates
              data010.AisleType = val.AisleType
              data010.Height = val.height
              data010.EastWestDistance = val.eastwestDistance
              data010.SourthNorthDistance = val.sourthnorthDistance
              data010.id = val.id
              data010.name = val.name
              methodsMap.sendMsgTo2D(data010)
            })
          }
          if (barr.sunRoomObstacleModels.length>0) {
            barr.sunRoomObstacleModels.forEach(val => {
              var data040 = {}
              val.name = '斜屋顶阳光房'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data040.XCoordinates = val.xCoordinates
              data040.YCoordinates = val.yCoordinates
              data040.EastWestDistance = val.eastwestDistance
              data040.id = val.id
              data040.name = val.name
              data040.Height = val.height
              data040.SourthNorthDistance = val.sourthnorthDistance
              methodsMap.sendMsgTo2D(data040)
            })
          }
          if (barr.ridgeOrnamentObstacleModels.length>0) {
            barr.ridgeOrnamentObstacleModels.forEach(val => {
              var data041 = {}
              val.name = '斜屋顶屋脊装饰物'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data041.Height = val.Height
              data041.Width = val.Width
              data041.WestSideDistance = val.WestSideDistance
              data041.id = val.id
              data041.name = val.name
              data041.TrueOrFalseRemove = val.TrueOrFalseRemove
              methodsMap.sendMsgTo2D(data041)
            })
          }
        }
      } else {
        if (dataInfo !== null) {
        // if (dataInfo.billboardObstacleModels.length>0) {
        //   dataInfo.billboardObstacleModels.forEach(val => {
        //     var data2 = {}
        //     val.name = '广告牌'
        //     if (val.trueOrFalseRemove === true) {
        //       val.trueOrFalseRemove = 1
        //     } else {
        //       val.trueOrFalseRemove = 0
        //     }
        //     for (const i in val) {
        //       if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'name') {
        //         val[i] = val[i] / 10
        //       }
        //     }
        //     data2.XCoordinates = val.xCoordinates
        //     data2.YCoordinates = val.yCoordinates
        //     data2.EastWestDistance = val.eastWestDistance
        //     data2.id = val.id
        //     data2.name = val.name
        //     data2.Height = val.height
        //     data2.SourthNorthDistance = val.sourthNorthDistance
        //     data2.TrueOrFalseRemove = val.trueOrFalseRemove
        //     methodsMap.sendMsgTo2D(data2)
        //   })

        // // methodsMap.sendMsgTo2D(dataInfo.billboardObstacleModels)
        // }
          if (dataInfo.chimneyObstacleModels.length>0) {
            dataInfo.chimneyObstacleModels.forEach(val => {
              var data3 = {}
              // val.name = '烟囱'
              if (val.chimneyDiam !== 0) {
                val.name = '斜屋顶圆形烟囱'
              } else {
                val.name = '斜屋顶矩形烟囱'
              }
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.trueOrFalseAdjustable === true) {
                val.trueOrFalseAdjustable = 1
              } else {
                val.trueOrFalseAdjustable = 0
                val.rearHeight = val.currentHeight
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'trueOrFalseAdjustable' && i !== 'name' && i !== 'chimneyType') {
                  val[i] = val[i] / 10
                }
              }
              data3.XCoordinates = val.xCoordinates
              data3.YCoordinates = val.yCoordinates
              data3.ChimneyType = val.chimneyType
              data3.EastWestLength = val.eastWestLength
              data3.id = val.id
              data3.name = val.name
              data3.Height = val.height
              data3.SourthNorthLength = val.sourthNorthLength
              data3.TrueOrFalseRemove = val.trueOrFalseRemove
              data3.CurrentHeight = val.currentHeight
              data3.TrueOrFalseAdjustable = val.trueOrFalseAdjustable
              data3.RearHeight = val.rearHeight
              data3.ChimneyDiam = val.chimneyDiam
              methodsMap.sendMsgTo2D(data3)
            })
            // methodsMap.sendMsgTo2D(dataInfo.chimneyObstacleModels)
          }
          if (dataInfo.heaterObstacleModels.length>0) {
            dataInfo.heaterObstacleModels.forEach(val => {
              var data = {}
              val.name = '斜屋顶热水器'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.trueOrFalseMove === true) {
                val.trueOrFalseMove = 1
                data.RearTrueOrFalseRemove = val.rearTrueOrFalseRemove / 10
                data.RearTrueOrFalseMove = val.rearTrueOrFalseMove / 10
              } else {
                val.trueOrFalseMove = 0
                data.RearTrueOrFalseRemove = val.xCoordinates / 10
                data.RearTrueOrFalseMove = val.yCoordinates / 10
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'name' && i !== 'trueOrFalseMove') {
                  val[i] = val[i] / 10
                }
              }
              data.XCoordinates = val.xCoordinates
              data.YCoordinates = val.yCoordinates
              data.SourthHeight = val.sourthHeight
              data.NorthHeight = val.northHeight
              data.EastWestDistance = val.eastWestDistance
              data.id = val.id
              data.name = val.name
              data.SourthNorthDistance = val.sourthNorthDistance
              data.TrueOrFalseRemove = val.trueOrFalseRemove
              data.TrueOrFalseMove = val.trueOrFalseMove

              methodsMap.sendMsgTo2D(data)
            })
            // methodsMap.sendMsgTo2D(data)
            // methodsMap.sendMsgTo2D(dataInfo.heaterObstacleModels, { name: '热水器' })
          }
          if (dataInfo.neighborHouseObstacleModels.length>0) {
            dataInfo.neighborHouseObstacleModels.forEach(val => {
              var data4 = {}
              val.name = '斜屋顶邻居房屋'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data4.XCoordinates = val.xCoordinates
              data4.YCoordinates = val.yCoordinates
              data4.EastWestDistance = val.eastWestDistance
              data4.id = val.id
              data4.name = val.name
              data4.Height = val.height
              data4.SourthNorthDistance = val.sourthNorthDistance
              methodsMap.sendMsgTo2D(data4)
            })

            // methodsMap.sendMsgTo2D(dataInfo.neighborHouseObstacleModels)
          }
          if (dataInfo.outSideObstacleModels.length>0) {
            dataInfo.outSideObstacleModels.forEach(val => {
              var data5 = {}
              val.name1 = '斜屋顶屋外障碍物'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'name1' && i !== 'trueOrFalseRemove') {
                  val[i] = val[i] / 10
                }
              }
              data5.XCoordinates = val.xCoordinates
              data5.YCoordinates = val.yCoordinates
              data5.EastWestDistance = val.eastWestDistance
              data5.id = val.id
              data5.name = val.name1
              data5.Name = val.name
              data5.Height = val.height
              data5.SourthNorthDistance = val.sourthNorthDistance
              data5.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data5)
            })
          }
          if (dataInfo.parapetWallsObstacleModels.length>0) {
            dataInfo.parapetWallsObstacleModels.forEach(val => {
              var data6 = {}
              val.name = '斜屋顶女儿墙'
              if (val.trueOrFalseRemove === 1) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }

              if (val.trueOrFalseDecorations === true) {
                val.trueOrFalseDecorations = 1
              } else {
                val.trueOrFalseDecorations = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove' && i !== 'trueOrFalseDecorations') {
                  val[i] = val[i] / 10
                }
              }
              data6.id = val.id
              data6.name = val.name
              data6.Thickness = val.thickness
              data6.EastHeight = val.eastHeight
              data6.WestHeight = val.westHeight
              data6.SourthHeight = val.sourthHeight
              data6.NorthSideHigh = val.northHeight
              data6.TrueOrFalseDecorations = val.trueOrFalseDecorations
              data6.DecorationsHeight = val.decorationsHeight
              data6.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data6)
            })

            // methodsMap.sendMsgTo2D(dataInfo.parapetWallsObstacleModels)
          }
          if (dataInfo.roofingObstacleModels.length>0) {
            dataInfo.roofingObstacleModels.forEach(val => {
              var data7 = {}
              val.name1 = '斜屋顶屋面障碍物'
              if (val.trueOrFalseMove === true) {
                data7.RearTrueOrFalseRemove = val.rearTrueOrFalseRemove / 10
                data7.RearTrueOrFalseMove = val.rearTrueOrFalseMove / 10
              } else {
                data7.RearTrueOrFalseRemove = val.xCoordinates / 10
                data7.RearTrueOrFalseMove = val.yCoordinates / 10
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'name1' && i !== 'trueOrFalseRemove' && i !== 'trueOrFalseMove') {
                  val[i] = val[i] / 10
                }
              }
              data7.id = val.id
              data7.name = val.name1
              data7.Name = val.name
              data7.XCoordinates = val.xCoordinates
              data7.YCoordinates = val.yCoordinates
              data7.EastWestDistance = val.eastWestDistance
              data7.SourthNorthDistance = val.sourthNorthDistance
              data7.Height = val.height
              data7.TrueOrFalseMove = val.trueOrFalseMove
              data7.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data7)
            })

            // methodsMap.sendMsgTo2D(dataInfo.roofingObstacleModels)
          }
          if (dataInfo.treeObstacleModels.length>0) {
            dataInfo.treeObstacleModels.forEach(val => {
              var data8 = {}
              if (val.trueOrFalseRemove === 1) {
                data8.TrueOrFalseRemove = val.trueOrFalseRemove
              } else {
                data8.TrueOrFalseRemove = val.canopyDiameter
              }
              val.name = '斜屋顶树'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove') {
                  val[i] = val[i] / 10
                }
              }
              data8.id = val.id
              data8.name = val.name
              data8.XCoordinates = val.xCoordinates
              data8.YCoordinates = val.yCoordinates
              data8.TrunkHeight = val.trunkHeight
              data8.TrunkDiameter = val.trunkDiameter
              data8.CanopyHeight = val.canopyHeight
              data8.CanopyDiameter = val.canopyDiameter
              // data8.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data8)
            })

            // methodsMap.sendMsgTo2D(dataInfo.treeObstacleModels)
          }
          if (dataInfo.wireObstacleModels.length>0) {
            dataInfo.wireObstacleModels.forEach(val => {
              var data9 = {}
              val.name = '斜屋顶电线'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              if (val.wireType === 2) {
                if (val.wireLocation === 4) {
                  val.wireLocation = 1
                } else if (val.wireLocation === 5) {
                  val.wireLocation = 2
                } else if (val.wireLocation === 6) {
                  val.wireLocation = 3
                } else if (val.wireLocation === 7) {
                  val.wireLocation = 4
                }
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'trueOrFalseRemove' && i !== 'wireType' && i !== 'wireLocation' && i !== 'voltage') {
                  val[i] = val[i] / 10
                }
              }
              data9.id = val.id
              data9.name = val.name
              data9.WireHeight = val.wireHeight
              data9.WireDiameter = val.wireDiameter
              data9.Voltage = val.voltage
              data9.TrueOrFalseRemove = val.trueOrFalseRemove
              data9.WireType = val.wireType
              data9.WireLocation = val.wireLocation
              data9.distance1 = val.southeastCorner
              data9.distance2 = val.northeastCorner
              methodsMap.sendMsgTo2D(data9)
            })

            // methodsMap.sendMsgTo2D(dataInfo.wireObstacleModels)
          }
          if (dataInfo.wirePoleObstacleModels.length>0) {
            dataInfo.wirePoleObstacleModels.forEach(val => {
              var data1 = {}
              val.name = '斜屋顶电线杆'
              if (val.trueOrFalseRemove === true) {
                val.trueOrFalseRemove = 1
              } else {
                val.trueOrFalseRemove = 0
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data1.XCoordinates = val.xCoordinates
              data1.YCoordinates = val.yCoordinates
              data1.Diameter = val.diameter
              data1.Height = val.height
              data1.TrueOrFalseRemove = val.trueOrFalseRemove
              data1.id = val.id
              data1.name = val.name
              methodsMap.sendMsgTo2D(data1)
            })
          }
          if (dataInfo.roadObstacleModels.length>0) {
            //  msg.AisleType,msg.EastWestDistance,msg.SourthNorthDistance,msg.Height,msg.XCoordinates,msg.YCoordinates

            dataInfo.roadObstacleModels.forEach(val => {
              if (val.AisleType === 2) {
                val.AisleType = 0
              }
              var data10 = {}
              val.name = '斜屋顶上人通道'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'name' && i !== 'aisleType') {
                  val[i] = val[i] / 10
                }
              }
              data10.XCoordinates = val.xCoordinates
              data10.YCoordinates = val.yCoordinates
              data10.AisleType = val.aisleType
              data10.Height = val.height
              data10.EastWestDistance = val.eastWestDistance
              data10.SourthNorthDistance = val.sourthNorthDistance
              data10.id = val.id
              data10.name = val.name
              methodsMap.sendMsgTo2D(data10)
            })
          }
          if (dataInfo.sunRoomObstacleModels.length>0) {
            //  msg.AisleType,msg.EastWestDistance,msg.SourthNorthDistance,msg.Height,msg.XCoordinates,msg.YCoordinates

            dataInfo.sunRoomObstacleModels.forEach(val => {
              var data11 = {}
              val.name = '斜屋顶阳光房'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] / 10
                }
              }
              data11.XCoordinates = val.xCoordinates
              data11.YCoordinates = val.yCoordinates
              data11.EastWestDistance = val.eastWestDistance
              data11.id = val.id
              data11.name = val.name
              data11.Height = val.height
              data11.SourthNorthDistance = val.sourthNorthDistance
              methodsMap.sendMsgTo2D(data11)
            })
          }
          if (dataInfo.ridgeOrnamentObstacleModels.length>0) {
            dataInfo.ridgeOrnamentObstacleModels.forEach(val => {
              var data014 = {}
              val.name = '斜屋顶屋脊装饰物'
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'trueOrFalseRemove' && i !== 'name') {
                  val[i] = val[i] / 10
                }
              }
              data014.Height = val.height
              data014.Width = val.width
              data014.WestSideDistance = val.westSideDistance
              data014.id = val.id
              data014.name = val.name
              data014.TrueOrFalseRemove = val.trueOrFalseRemove
              methodsMap.sendMsgTo2D(data014)
            })
          }
        }
      }
    });
    sessionStorage.setItem("data", JSON.stringify(globleData));

    const methodsMap = {
      editObstacle(params) {
        console.log(params, '编辑障碍物*****')
        // this.show = true
        data.shelterShow = true
        data.shelterComShow = true
        if (params.typeEnum === 1) {
          console.log(params, '女儿墙信息编辑')
          data.shelterComShow = '女儿墙'
          setTimeout(() => {
            emitter.emit('editPapert', params)
            emitter.emit('editstatePapert', 1)
          }, 500)
        }
        if (params.typeEnum === 2 || params.typeEnum === 3) {
          data.shelterComShow = '烟囱'
          setTimeout(() => {
            emitter.emit('editRectChimney', params)
            emitter.emit('editstateChimney', 1)
          }, 500)
        }
        if (params.typeEnum === 4) {
          data.shelterComShow = '热水器'
          setTimeout(() => {
            emitter.emit('editHotWater', params)
            emitter.emit('editstate', 1)
          }, 500)
        }
        if (params.typeEnum === 5) {
          data.shelterComShow = '屋面障碍物'
          setTimeout(() => {
            emitter.emit('editRoomObstacle', params)
            emitter.emit('editstateRoomObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 6) {
          data.shelterComShow = '电线杆'
          setTimeout(() => {
            emitter.emit('editRoomOutObstaclePoleLine', params)
            emitter.emit('editstateRoomOutObstaclePoleLine', 1)
          }, 500)
        }
        if (params.typeEnum === 7) {
          data.shelterComShow = '树'
          setTimeout(() => {
            emitter.emit('editTree', params)
            emitter.emit('editstateTree', 1)
          }, 500)
        }
        if (params.typeEnum === 8) {
          data.shelterComShow = '屋外障碍物'
          setTimeout(() => {
            emitter.emit('editRoomOutObstacle', params)
            emitter.emit('editstateRoomOutObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 9) {
          data.shelterComShow = '广告牌'
          setTimeout(() => {
            emitter.emit('editRoomOutObstacleAds', params)
            emitter.emit('editstateRoomOutObstacleAds', 1)
          }, 500)
        }
        if (params.typeEnum === 10) {
          data.shelterComShow = '上人通道'
          setTimeout(() => {
            emitter.emit('editChannel', params)
            emitter.emit('editstateChannel', 1)
          }, 500)
        }
        if (params.typeEnum === 11) {
          data.shelterComShow = '邻居房屋'
          setTimeout(() => {
            emitter.emit('editNeiborRoom', params)
            emitter.emit('editstateNeiborRoom', 1)
          }, 500)
        }
        if (params.typeEnum === 12) {
          data.shelterComShow = '电线'
          setTimeout(() => {
            emitter.emit('editWireObstacle', params)
            emitter.emit('editstateWireObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 13) {
          data.shelterComShow = '阳光房'
          setTimeout(() => {
            emitter.emit('editSunHouse', params)
            emitter.emit('editstateSunHouse', 1)
          }, 500)
        }
        if (params.typeEnum === 14) {
          data.shelterComShow = '屋脊装饰物'
          setTimeout(() => {
            emitter.emit('editOrnament', params)
            emitter.emit('editstateOrnament', 1)
          }, 500)
        }
      },
      // goBack() {
      //   router.go(-1);
      // },
      sendMsgTo2D(v) {
        console.log(v, '需要传递的数据')
        setTimeout(() => {
          data.iframeWin.postMessage(v, '*')
        }, 1000)
      },
      previewEvent() {
        const flatCanvas = sessionStorage.getItem('flatCanvas')
        if (flatCanvas !== null) {
          const datablob = this.dataURItoBlob(sessionStorage.getItem('flatCanvas'))
          this.blobToBase64(datablob)
          window.frames[0].global_ObstacleList.forEach(val => {
            const str1 = val.id.slice(0, 8);
            const str2 = val.id.slice(8, 12);
            const str3 = val.id.slice(12, 16);
            const str4 = val.id.slice(16, 20);
            const str5 = val.id.slice(20);
            const strId = str1 + '-' + str2 + '-' + str3 + '-' + str4 + '-' + str5
            if (val.typeEnum === 1) {
              if (val.hasDecoration === 1) {
                val.hasDecoration = true
              } else {
                val.hasDecoration = false
              }
              if (val.isRemove === 1) {
                val.isRemove = 1
              } else {
                val.isRemove = 3
              }
              // 需要替换的名字
              const keyMap = {
                isRemove: 'trueOrFalseRemove',
                hasDecoration: 'TrueOrFalseDecorations'
              }

              for (const i in val) {
                if (i !== 'id' && i !== 'isRemove' && i !== 'hasDecoration' && i !== 'roofID' && i !== 'trueOrFalseRemove') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              console.log(val, '女儿墙')
              globleData.parapetWallsObstacleModels.push(val)
              console.log(globleData, '------------------')
            }
            if (val.typeEnum === 2 || val.typeEnum === 3) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              if (val.isChage === 1) {
                val.isChage = true
              } else {
                val.isChage = false
                val.chageHeight = val.currentHeight
              }
              if (val.typeEnum === 2) {
                val.chimneyShapeType = 1
              } else {
                val.chimneyShapeType = 2
              }
              // 需要替换的名字
              const keyMap = {
                isRemove: 'trueOrFalseRemove',
                hasDecoration: 'TrueOrFalseDecorations',
                isChage: 'TrueOrFalseAdjustable',
                chageHeight: 'rearHeight',
                // currentHeight: 'currentHeight',
                X: 'XCoordinates',
                Y: 'YCoordinates',
                eastwestDistance: 'eastWestLength',
                sourthnorthDistance: 'sourthNorthLength',
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'isRemove' && i !== 'isChage' && i !== 'hasDecoration' && i !== 'roofID' && i !== 'chimneyType' && i !== 'chimneyShapeType') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }

                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }

              globleData.chimneyObstacleModels.push(val)
            }
            if (val.typeEnum === 4) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              if (val.isChangePostion === 1) {
                val.isChangePostion = true
              } else {
                val.isChangePostion = false
                val.rearTrueOrFalseRemove = val.X
                val.rearTrueOrFalseMove = val.Y
              }
              // if (val.rearTrueOrFalseMove === '' || val.rearTrueOrFalseMove === null || val.rearTrueOrFalseMove === undefined) {
              //   val.rearTrueOrFalseRemove = 0
              //   val.rearTrueOrFalseMove = 0
              // }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                isRemove: 'trueOrFalseRemove',
                isChangePostion: 'trueOrFalseMove'
              }
              for (const i in val) {
                if (i !== 'isRemove' && i !== 'isChangePostion' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }

              globleData.heaterObstacleModels.push(val)
            }
            if (val.typeEnum === 5) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              if (val.isChangePostion === 1) {
                val.isChangePostion = true
              } else {
                val.isChangePostion = false
                val.rearTrueOrFalseRemove = val.X
                val.rearTrueOrFalseMove = val.Y
              }
              // if (val.RearTrueOrFalseMove === '' || val.RearTrueOrFalseMove === null || val.RearTrueOrFalseMove === undefined) {
              //   val.RearTrueOrFalseRemove = 0
              //   val.RearTrueOrFalseMove = 0
              // }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                isRemove: 'trueOrFalseRemove',
                isChangePostion: 'trueOrFalseMove',
                obstacleName: 'name'
              }
              for (const i in val) {
                if (i !== 'obstacleName' && i !== 'isRemove' && i !== 'isChangePostion' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }

              globleData.roofingObstacleModels.push(val)
            }
            if (val.typeEnum === 6) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                diam: 'Diameter',
                isRemove: 'TrueOrFalseRemove'
              }
              for (const i in val) {
                if (i !== 'isRemove' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }

              globleData.wirePoleObstacleModels.push(val)
            }
            if (val.typeEnum === 7) {
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                isRemove: 'TrueOrFalseRemove',
                height1: 'TrunkHeight',
                diam1: 'TrunkDiameter',
                height2: 'CanopyHeight',
                diam2: 'CanopyDiameter',
              }
              for (const i in val) {
                if (i !== 'isRemove' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              // if (val.TrueOrFalseRemove === 1) {
              //   val.TrueOrFalseRemove = true
              // } else {
              //   val.TrueOrFalseRemove = false
              // }

              globleData.treeObstacleModels.push(val)
            }
            if (val.typeEnum === 8) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                isRemove: 'TrueOrFalseRemove',
                obstacleName: 'Name',
              }
              for (const i in val) {
                if (i !== 'obstacleName' && i !== 'isRemove' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }

              globleData.outSideObstacleModels.push(val)
            }
            // if (val.typeEnum === 9) {
            //   // 需要替换的名字
            //   const keyMap = {
            //     X: 'xCoordinates',
            //     Y: 'yCoordinates',
            //     isRemove: 'TrueOrFalseRemove',
            //   }
            //   for (const i in val) {
            //     if (i !== 'isRemove' && i !== 'id' && i !== 'roofID') {
            //       val[i] = val[i] * 10
            //     }
            //     // 往对象里添加键值
            //     const roofID = 'roofID'
            //     const roofIDval = guid()
            //     val[roofID] = roofIDval
            //     // 替换对象里面的键
            //     const newKey = keyMap[i]
            //     if (newKey) {
            //       val[newKey] = val[i]
            //       delete val[i]
            //     }
            //     if (i === 'id') {
            //       val[i] = strId
            //     }
            //     // 删除对象里的键值
            //     if (i === 'typeEnum') {
            //       delete val[i]
            //     }
            //   }

            //   globleData.billboardObstacleModels.push(val)
            // }
            if (val.typeEnum === 10) {
              if (val.AisleType === 0) {
                val.AisleType = 2
              }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                channelType: 'AisleType',
              }
              for (const i in val) {
                if (i !== 'channelType' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              globleData.roadObstacleModels.push(val)
            }
            if (val.typeEnum === 11) {
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              globleData.neighborHouseObstacleModels.push(val)
            }
            if (val.typeEnum === 12) {
              if (val.isRemove === 1) {
                val.isRemove = true
              } else {
                val.isRemove = false
              }
              if (val.wireType === 2) {
                if (val.direction === 1) {
                  val.direction = 4
                } else if (val.direction === 2) {
                  val.direction = 5
                } else if (val.direction === 3) {
                  val.direction = 6
                } else if (val.direction === 4) {
                  val.direction = 7
                }
              }
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
                height: 'WireHeight',
                diam: 'WireDiameter',
                isRemove: 'TrueOrFalseRemove',
                direction: 'WireLocation',
                wireRange: 'Voltage',
                value1: 'southeastCorner',
                value2: 'northeastCorner',
              }
              for (const i in val) {
                if (i !== 'direction' && i !== 'wireType' && i !== 'isRemove' && i !== 'wireRange' && i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              globleData.wireObstacleModels.push(val)
            }
            if (val.typeEnum === 13) {
              // 需要替换的名字
              const keyMap = {
                X: 'xCoordinates',
                Y: 'yCoordinates',
              }
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 替换对象里面的键
                const newKey = keyMap[i]
                if (newKey) {
                  val[newKey] = val[i]
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
              }
              globleData.sunRoomObstacleModels.push(val)
            }
            if (val.typeEnum === 14) {
              for (const i in val) {
                if (i !== 'id' && i !== 'roofID' && i !== 'TrueOrFalseRemove') {
                  val[i] = val[i] * 10
                }
                // 往对象里添加键值
                const roofID = 'roofID'
                const roofIDval = guid()
                val[roofID] = roofIDval
                // 删除对象里的键值
                if (i === 'typeEnum') {
                  delete val[i]
                }
                if (i === 'id') {
                  val[i] = strId
                }
              }
              globleData.ridgeOrnamentObstacleModels.push(val)
            }
          });

          sessionStorage.setItem('barrier', JSON.stringify(globleData))
          router.push('/slopingRoofPreview')
          data.iframeWin.postMessage('预览重置画布', '*')
        }
      },
      blobToBase64(blob, callback) {
        var _this = this
        const a = new FileReader()
        a.readAsDataURL(blob)
        a.onload = function () {
          _this.imgData = this.result
          _this.dataURLtoFile(this.result)
          var data = new FormData()
          data.append('File', _this.dataURLtoFile(this.result))
          data.append('UploadType', 1)
          // 上传canvas到数据库
          UploadFile(data).then((res) => {
            if (res.data.success) {
              sessionStorage.setItem('grassImg', res.data.data)
            } else {
              _this.$Dialog.alert({
                message: res.data.message
              })
            }
          })
        }
      },
      // base64转blob
      dataURItoBlob(base64Data) {
        var byteString
        if (base64Data.split(',')[0].indexOf('base64') >= 0) { byteString = atob(base64Data.split(',')[1]) } else byteString = unescape(base64Data.split(',')[1])
        var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], {
          type: mimeString
        })
      },
      dataURLtoFile(dataurl, filename = 'file') {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const suffix = mime.split('/')[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime
        })
      },
      infoEvent() {
        data.popShow = true;
      },
      shelterTypeShowEvent() {
        if (data.activeName === "c") {
          sessionStorage.removeItem("view");
          sessionStorage.setItem("view", 1);
        } else if (data.activeName === "d") {
          sessionStorage.removeItem("view");
          sessionStorage.setItem("view", 2);
        }
        data.shelterTypeShow = !data.shelterTypeShow;
      },
      chooseShelterType(item) {
        data.shelterComShow = item;
        data.shelterTypeShow = false;
        data.shelterShow = true;
      },
      clickTab() {
        if (data.activeName === "b") {
          SideViewShow.value = true;
        } else {
          SideViewShow.value = false;
          data.SideViewNum = "侧视图";
        }
      },
      chooseSideView(item) {
        SideViewShow.value = false;
        data.SideViewNum = item;
      },
      getPopupClose(value) {
        emitter.emit('editstate', 2)
        console.log('父页面接收到的信息')
        methodsMap.sendMsgTo2D(toRaw(value))
        data.popShow = false;
        data.shelterShow = false;
        setTimeout(() => {
          if (sessionStorage.getItem('hit') !== null) {
            Dialog.alert({
              message: sessionStorage.getItem('hit')
            }).then(() => {
              sessionStorage.removeItem('hit')
            })
          }
        }, 2000)
      }
    }

    return {
      ...toRefs(data),
      // goBack,
      // previewEvent,
      // shelterTypeShowEvent,
      // chooseShelterType,
      // infoEvent,
      // getPopupClose,
      ...methodsMap,
      iframe,
      // sendMsgTo2D,
      // chooseSideView,
      SideViewShow,
      // clickTab,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/style/jinlang/slopingRoof.scss";
@import "@/style/qingtian/slopingRoof.scss";
#iframe {
  position: absolute;
  width: 100vw;
  top: -40%;
  height: 750px !important;
}
.previewBtn {
  position: absolute;
  margin-top: 35%;
  margin-left: 5%;
  border-style: none;
}
</style>
